<!-- tap to top -->
<div
  class="tap-top top-cls"
  (click)="tapToTop()"
  [ngStyle]="{ display: show ? 'block' : 'none' }"
>
  <div>
    <svg
      class="icon"
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      fit=""
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
    >
      <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  </div>
</div>
<!-- tap to top -->
