import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { unAuthorizedUrl } from '../../routing/route-urls';
import { tap } from 'rxjs/operators';
import { LocationService } from '../location/location.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  country_id: any;

  constructor(
    private auth: AuthService,
    private locationService: LocationService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getToken();
    // this.country_id = this.locationService.getCountryId();
    this.locationService.location$.subscribe((loc) => {
      this.country_id = loc.country == 'India' ? 1 : 2;
    });

    //this.auth.getCountry();

    let unAutherizedPath = unAuthorizedUrl;
    let api_name = '';
    unAutherizedPath = unAutherizedPath.filter((obj) => {
      api_name = obj;
      let url = req.url.split('?');
      let reqUrl = req.url;
      if (url && url.length > 0) {
        reqUrl = url[0];
      }
      return environment.api_url + obj === reqUrl;
    });
    if (unAutherizedPath && unAutherizedPath.length <= 0) {
      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          'country-id': `${this.country_id}`,
        },
      });
      return next.handle(request);
    } else {
      const request = req.clone({
        setHeaders: { 'country-id': `${this.country_id}` },
      });
    }

    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            return event;
          }
        },
        (error) => {
          let errorObj = error;
          return errorObj;
        }
      )
    );
  }
}
