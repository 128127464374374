import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar-component/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  notify(msg: any, type: number) {
    let className = '';
    if (type === 1) {
      className = 'snack-success';
    }
    if (type === 2) {
      className = 'snack-error';
    }
    this.snackBar.open(msg, 'X', {
      duration: 3000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['snackbar', className],
    });
  }

  notifyHtml(html: any, type: number) {
    let className = '';
    if (type === 1) {
      className = 'snack-success';
    }
    if (type === 2) {
      className = 'snack-error';
    }
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      data: html,
      panelClass: ['snackbar', className],
    });
  }

  Cartnotify(msg: any) {
    let className = '';
    // if (Action === 'YES') {
    //   className = 'snack-success';
    // }
    // if (Action === 'NO') {
    //   className = 'snack-error';
    // }
    this.snackBar.open(msg, 'X', {
      duration: 3000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['snackbar', className],
    });
  }
}
