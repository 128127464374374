import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';

import { NavigationExtras, Router } from '@angular/router';
import { RequestService } from 'src/app/services/http/request.service';
import { CMS_GET } from 'src/environments/api-path';
import { SubSink } from 'subsink';
import { CmsService } from '../../pages/cms-pages/cms.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isLoading: boolean;
  messageType: number = 1;
  message: string = '';
  unsubscribe$ = new SubSink();
  loading: boolean;
  cmsList: any;
  cmsPage: any;
  footerLink: any;
  constructor(
    private router: Router,
    private request: RequestService,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private cmsService: CmsService,

    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this._getContent();
  }

  // navigateToAboutPantheia(cmsData: any) {
  //   console.log(cmsData);

  //   const navigationExtras: NavigationExtras = {
  //     state: {
  //       cmsData: cmsData,
  //     },
  //   };
  //   this.router.navigate(['/cms/about_pantheia'], navigationExtras);
  // }
  navigateToAboutPantheia() {
    this.router.navigate(['/cms/about_pantheia']);
  }

  // public _getContent() {
  //   this.isLoading = true;
  //   this.unsubscribe$.add(
  //     this.request.GET(CMS_GET, {}).subscribe(
  //       (response) => {
  //         if (response['status']) {
  //           this.loading = false;
  //           this.cmsList = response['data'];
  //           this.footerLink = this.cmsList.map((title) => {
  //             if (title.is_active == '1') {
  //               return title.title;
  //             }
  //           });
  //           console.log(this.footerLink);

  //           const filterData = this.cmsList.find(
  //             (item) => item.page == 'footer-content'
  //           );
  //           this.cmsPage = this.sanitizer.bypassSecurityTrustHtml(
  //             filterData?.content
  //           );
  //           // this.cmsPage = filterData.content;
  //         } else {
  //           this.loading = false;
  //           this.messageType = 2;
  //           this.message = response.message;
  //         }
  //       },
  //       (err) => {
  //         this.loading = false;
  //         this.messageType = 2;
  //         this.message = err.message;
  //       }
  //     )
  //   );
  // }

  public _getContent() {
    this.isLoading = true;
    this.unsubscribe$.add(
      this.request.GET(CMS_GET, {}).subscribe(
        (response) => {
          if (response['status']) {
            this.loading = false;
            this.cmsList = response['data'];

            // Filter and map only active titles
            this.footerLink = this.cmsList
              .filter((title) => title.is_active === '1' && title.title)
              .map((title) => title.title);

            console.log(this.footerLink);

            // Find the specific CMS page by page name
            const footerContent = this.cmsList.find(
              (item) => item.page === 'footer-content'
            );

            // Check if the footerContent is found before accessing its content
            if (footerContent) {
              this.cmsPage = this.sanitizer.bypassSecurityTrustHtml(
                footerContent.content
              );
            } else {
              // Handle the case where footerContent is not found
              console.error('Footer content not found in CMS data.');
            }
          } else {
            this.loading = false;
            this.messageType = 2;
            this.message = response.message;
          }
        },
        (err) => {
          this.loading = false;
          this.messageType = 2;
          this.message = err.message;
        }
      )
    );
  }

  public getTitleData(event: any) {
    const clickedTitle = event.target.textContent.trim();

    const clickedData = this.cmsList.find(
      (item) => item.title === clickedTitle
    );

    if (clickedData) {
      this.cmsPage = this.sanitizer.bypassSecurityTrustHtml(
        clickedData.content
      );
      this.cmsService.updateData(clickedData);
      this.navigateToAboutPantheia();
    } else {
    }
  }
}
