import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageCookieService } from '../cookie/storage-cookie.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { LocationService } from '../location/location.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  [x: string]: any;
  hitnum: number = 0;
  constructor(
    private jwtHelper: JwtHelperService,
    private cookie: StorageCookieService,
    private http: HttpClient,
    private locationService: LocationService
  ) {}

  setUser(token) {
    if (token) {
      this.cookie.setCookie(token.access_token, 'USER');
      this.cookie.setCookie(
        token.first_name + ' ' + token.last_name,
        'USERNAME'
      );
      ///localStorage.setItem('USER_DATA',JSON.stringify(token));
    }
  }

  isAuthenticated(): boolean {
    //console.log(JSON.parse(this.cookie.getCookie('USER')))
    //const token = JSON.parse(this.cookie.getCookie('USER')).access_token;
    const token = this.cookie.getCookie('USER');
    if (token) {
      return !this.isTokenValid(token);
    }
    return false;
  }
  gettokenforcart(): string {
    //console.log(JSON.parse(this.cookie.getCookie('USER')))
    //const token = JSON.parse(this.cookie.getCookie('USER')).access_token;
    const carttoken = this.cookie.getCookie('CARTTOKEN');
    if (carttoken) {
      return carttoken;
    } else {
      var token = uuidv4();
      this.cookie.setCookie(token, 'CARTTOKEN');
      return token;
    }
  }

  isTokenValid(token) {
    if (!token) {
      return false;
    }
    return this.jwtHelper.isTokenExpired(token);
  }

  decodeToken(token) {
    if (token) {
      return this.jwtHelper.decodeToken(token);
    }
    return false;
  }

  // setPrivateKey(pkey) {
  //   if (pkey) {
  //     this.cookie.setCookie(pkey, 'autherization');
  //   }
  // }

  getPrivateKey() {
    const userDetail = this.getUser();
    return `&%@)${userDetail['token']}${userDetail._id}*^$`;
  }

  getUser() {
    const token = this.cookie.getCookie('USER');
    if (token) {
      const userdata = this.jwtHelper.decodeToken(token);
      return userdata.sub;
    }
    return false;
  }
  async getCountry() {
    const usercountry = this.cookie.getCookie('USER_COUNTRY') ?? false;
    if (usercountry) {
      return usercountry;
    } else {
      if (this.hitnum == 0) {
        this.locationService.location$.subscribe((res) => {
          if (res) {
            console.log(res);
            if (res.country == 'India') {
              this.setCountry(1);
            } else {
              this.setCountry(2);
            }
          }
        });
      }
    }
  }
  /* getLocation(): Promise<any>
  {
    return new Promise((resolve, reject) => {
        this.http.get<any>('http://ip-api.com/json').subscribe(data => {
            resolve(data);
        },
        err => {
          console.log(err);
          reject(err);
        });
    });
  }*/
  setCountry(country) {
    this.cookie.setCookie(country, 'USER_COUNTRY');
    return true;
  }

  setlanguage(language) {
    this.cookie.setCookie(language, 'USER_LANGUAGE');
    return true;
  }
  getlanguage() {
    const userlan = this.cookie.getCookie('USER_LANGUAGE') ?? false;
    if (userlan) {
      return userlan;
    } else {
      return 'en';
    }
  }
  checkpermission(module, permission, role) {
    var returnval = false;
    role.forEach((e) => {
      if (e.name == module) {
        e.permission.forEach((per) => {
          if (per.name == permission && per.is_checked) {
            returnval = true;
          }
        });
      }
    });
    return returnval;
  }
  getToken() {
    return this.cookie.getCookie('USER') || null;
  }

  // setStorage(key, data) {
  //   if (data) {
  //     this.cookie.setCookie(data, key);
  //   }
  // }

  getStorage(key) {
    if (key) {
      return this.cookie.getCookie(key);
    } else {
      return false;
    }
  }

  // removeUser() {
  //  this.cookie.removeCookie(['user', 'autherization']);
  //   this.router.navigate(['/']);
  // }

  // setCookie(key, data) {
  //   this.cookie.setCookie(data, key);
  // }

  logOut(): void {
    this.cookie.removeCookie(['USER']);
    this.cookie.removeCookie(['USERNAME']);
    //this.router.navigate(['/']);
  }

  getCookie(key) {
    return this.cookie.getCookie(key);
  }

  setCookies(key, data) {
    this.cookie.setCookies(data, key);
  }

  getCookies(key) {
    this.cookie.getCookies(key);
  }
}
