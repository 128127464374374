import {
  Component,
  OnInit,
  ElementRef,
  NgZone,
  ViewChild,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import {
  LocationService,
  LocationData,
} from 'src/app/services/location/location.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SubSink } from 'subsink';
import {} from 'google.maps';
/// <reference types="google.maps" />

@Component({
  selector: 'app-location-popup',
  templateUrl: './location-popup.component.html',
  styleUrls: ['./location-popup.component.scss'],
})
export class LocationPopupComponent implements OnInit {
  unsubscribe$ = new SubSink();
  zoom: number = 14;
  address: string;
  private geoCoder;
  locationData: { lng: number; lat: number; locationAccess: boolean };
  searchBox;
  location: LocationData = {
    lat: 0,
    lng: 0,
    range: 25,
    city: '',
    country: '',
  };
  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('locationIcon')
  public locationIconRef: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<LocationPopupComponent>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private locationService: LocationService,
    private snack: SnackbarService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    //load Places Autocomplete
    this.loadSearchPlaces();
    this.getDafaultLocation();
  }

  getDafaultLocation() {
    this.unsubscribe$.add(
      this.locationService.location$.subscribe((res) => {
        if (res) {
          this.location = res;
          this.address = this.location.city + ',' + this.location.country;
          console.log('address',this.address)
        }
      })
    );
  }

  mapReady(event: any) {
    const map = event;
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(
      this.searchElementRef.nativeElement
    );
    // map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
    //   this.locationIconRef.nativeElement
    // );
  }
  loadSearchPlaces() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      const options = {
        componentRestrictions: { country: ['pa', 'in'] },
      };
      this.searchBox = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        options
      );
      // listen to place change event
      this.searchBox.addListener('place_changed', () =>
        this.goToSearchPlaces()
      );
    });
  }

  goToSearchPlaces() {
    this.ngZone.run(() => {
      //get the place result
      let place: google.maps.places.PlaceResult = this.searchBox.getPlace();
      //verify result
      if (place.geometry === undefined || place.geometry === null) {
        return;
      }
      this.location.lat = place.geometry.location.lat();
      this.location.lng = place.geometry.location.lng();
      this.zoom = 14;
      this.getAddress(this.location.lat, this.location.lng, false);
    });
  }

  // Get Current Location Coordinates
  async setCurrentLocation() {
    this.locationData = await this.locationService.getPosition();
    if (this.locationData.locationAccess) {
      this.location.lat = this.locationData.lat;
      this.location.lng = this.locationData.lng;
      this.zoom = 14;
      this.getAddress(this.location.lat, this.location.lng, true);
    } else {
      this.snack.notify('Location services are disabled', 2);
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.location.lat = $event.coords.lat;
    this.location.lng = $event.coords.lng;
    this.getAddress(this.location.lat, this.location.lng, false);
  }

  getAddress(latitude, longitude, saveLocation) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.fillInAddress(results[0], saveLocation);
            this.zoom = 14;
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
  fillInAddress(place, saveLocation) {
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'locality':
          this.location.city = component.long_name;
          break;
        case 'country':
          this.location.country = component.long_name;
          break;
      }
    }
    if (saveLocation) {
      this.saveLocation();
    }
  }

  saveLocation() {
    this.locationService.setLocation(this.location);
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  formatLabel(value: number) {
    if (value >= 10) {
      return Math.round(value) + 'mi';
    }

    return value;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
