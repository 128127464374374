import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../../../services/http/request.service';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { OnDemandPreloadService } from 'src/app/services/pre-loading-strategies/on-demand-preload.service';
@Component({
  selector: 'app-header-categories',
  templateUrl: './header-categories.component.html',
  styleUrls: ['./header-categories.component.scss'],
})
export class HeaderCategoriesComponent implements OnInit {
  unsubscribe$ = new SubSink();
  loading: boolean = false;
  category: Array<any> = [];

  constructor(
    private request: RequestService,
    private router: Router,
    private preloadService: OnDemandPreloadService
  ) {}

  ngOnInit(): void {
    this._fetchData();
  }

  _fetchData(): void {
    this.unsubscribe$.add(
      this.request.GET('get_category', {}).subscribe(
        (response) => {
          if (response['status']) {
            this.category = response['records'];
          } else {
          }
        },
        (err) => {
          this.loading = false;
        }
      )
    );
  }

  gotosearch(slug: string) {
    this.router.navigate(['/category', slug], {
      queryParams: {
        s: slug,
      },
    });
  }

  preloadModule() {
    this.preloadService.startPreload('category');
  }

  // get() {
  //   this.request.POST('most_visited_category', {}).subscribe((response) => {
  //     console.log('most viewed', response);
  //   });
  // }

  // getSub() {
  //   this.request
  //     .POST('getsubcategory', {})
  //     .subscribe((res) => console.log('sub category', res));
  // }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
