import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './components/layout/layout.module';
import { AuthInterceptor } from './services/interceptors/interceptors.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ShoppingListPopUpComponent } from './shared-component/shopping-list-pop-up/shopping-list-pop-up.component';
import { LazyImgDirective } from './directive/lazy-img.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { environment } from 'src/environments/environment';
import { AgmCoreModule } from '@agm/core';

export function tokenGetter() {
  //return localStorage.getItem("access_token");
  return '1dgd554dfgdfg54';
}

export function getCurrentLanguage() {
  return localStorage.getItem('currentLanguage')}
@NgModule({
  declarations: [AppComponent, ShoppingListPopUpComponent, LazyImgDirective],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MatSnackBarModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    AgmCoreModule.forRoot({
      apiKey: `${environment.google_api_key}&language=${getCurrentLanguage()}`,
      libraries: ['places'],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    CookieModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:4200/'],
        blacklistedRoutes: [''],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ShoppingListPopUpComponent],
})
export class AppModule {}
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/../../assets/i18n/', '.json');
}
