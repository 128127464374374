<app-left-sidebar></app-left-sidebar>

<div class="by_b" (scroll)="onWindowScroll($event)" id="header">
  <div class="header-base-wrapper b_a">
    <div class="header-wrapper b_a">
      <!-- *********** Header left********** -->
      <div class="header-left b_a">
        <div class="hem-menu" (click)="openSidebar()">
          <div class="g_a g_c a3_c">
            <span class="g_b">
              <img
                height="24px"
                width="24px"
                src="assets/images/menu-white.svg"
                alt="image"
              />
            </span>
          </div>
        </div>
        <a routerLink="" class="logo2">
          <img src="assets/images/logo.png" alt="LOGO2" />
        </a>

        <div class="location-container">
          <div right="xs" class="account header__order">
            <a class="l_a g_a ae_a g_c" (click)="openPopup()">
              <span class="g_b">
                <span class="b_a b_r">
                  <span class="b_a hf-bubble-button-icon-slide b_g b_b b_p">
                    <img
                      alt="null"
                      aria-hidden="true"
                      width="24"
                      height="24"
                      src="assets/images/city-location.svg"
                    />
                  </span>
                  <span class="b_a ae_b b_g b_b b_p z_a z_h z_i">{{
                    city
                  }}</span>
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <!-- *********** Header left end********** -->

      <!-- *********** Header Search********** -->
      <!-- <app-header-search class="search"></app-header-search> -->
      <ng-content></ng-content>

      <!-- *********** Header search********** -->

      <div class="header-right">
        <!-- ******** Header language ******** -->

        <app-new-header-language
          class="account header__account dropDownWhoteArrow"
        ></app-new-header-language>
        <!-- ******** Header language end ******** -->

        <!-- ******** Header Account ******** -->

        <app-new-header-account
          class="account header__account"
        ></app-new-header-account>
        <!-- ******** Hearder  Account end ******** -->

        <!-- ******** Hearder  Order ******** -->
        <div right="xs" class="account header__order">
          <a
            class="l_a g_a ae_a g_c"
            aria-label="My Items"
            data-uid="DmGTAxTu"
            routerLink="/order/orders-history"
            id="hf-easyreorder"
            (mouseover)="preloadModule('order')"
          >
            <span class="g_b">
              <span class="b_a b_r">
                <span class="b_a hf-bubble-button-icon-slide b_g b_b b_p">
                  <img
                    alt="null"
                    aria-hidden="true"
                    width="24"
                    height="24"
                    src="assets/images/order.png"
                  />
                </span>
                <span class="b_a ae_b b_g b_b b_p z_a z_h z_i">{{
                  "Orders" | translate
                }}</span>
              </span>
            </span>
          </a>
        </div>
        <!-- ******** Hearder  Order end ******** -->
        <!-- Header notification -->
        <div class="new_notification">
          <app-header-notification></app-header-notification>
        </div>
        <!-- Header notification end  -->
        <!-- ******** Hearder  cart  ******** -->

        <div class="account header__cart">
          <app-new-header-cart class="account header__cart">
          </app-new-header-cart>
          <!-- ******** Hearder  cart end ******** -->
        </div>
      </div>
    </div>
  </div>
</div>

<svg style="display: none">
  <symbol id="icon-location" viewBox="0 0 32 32">
    <path
      d="M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z"
    ></path>
  </symbol>

  <symbol id="icon-location-2" viewBox="0 0 24 24">
    <path
      d="M12 2c3.196 0 6 2.618 6 5.602 0 3.093-2.493 7.132-6 12.661-3.507-5.529-6-9.568-6-12.661 0-2.984 2.804-5.602 6-5.602m0-2c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
    />
  </symbol>

  <symbol id="icon-angle-down" viewBox="0 0 21 32">
    <path
      d="M19.196 13.143c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.25-0.179 0.411-0.179 0.143 0 0.304 0.071 0.411 0.179l7.018 7.018 7.018-7.018c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.268 0.179 0.411z"
    ></path>
  </symbol>
</svg>
