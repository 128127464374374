<div class="close">
  <i class="fa fa-times" (click)="onClose()" aria-hidden="true"></i>
</div>
<div class="container confirmationModelBox shoppingListPopUp">
  <div>
    <h3>
      <span
        ><strong>{{ "Choose Your List" }}</strong></span
      >
    </h3>
    <div>
      <!-- <ul *ngIf="data">
        <li *ngFor="let list of data">
          <input
            type="checkbox"
            id="{{ list?.id }}"
            class="form-check-input"
            (change)="passId(list?.id)"
          />
          <label for="{{ list?.id }}" class="form-check-label">
            {{ list?.name }}
          </label>
        </li>
      </ul> -->
      <ul>
        <!-- Assuming you have isLoading property in your component class -->
        <li *ngFor="let list of reverseShoppingList" class="shopping-list-item">
          <input
            type="checkbox"
            id="{{ list?.id }}"
            class="form-check-input shoppingListChecked"
            (change)="passId(list?.id)"
            [checked]="addedShoppingListIdsArray.includes(list?.id)"
          />
          <label for="{{ list?.id }}" class="form-check-label">
            {{ list?.name }}
          </label>
          <i
            class="fa fa-trash trash"
            (click)="deleteList(list['id'], list['name'])"
            aria-hidden="true"
          ></i>
        </li>

        <li *ngIf="isLoading" style="margin-left: 28%">
          <i
            style="margin-left: 28%"
            *ngIf="isLoading"
            class="fa fa-spinner fa-spin"
            aria-hidden="true"
          ></i>
        </li>
      </ul>
      <button class="create-shopping-list-button" (click)="openDialog()">
        <span class="plus-icon">+</span
        ><span class="createShoppingListBtnLbl"
          >Create a new Shopping List</span
        >
      </button>
    </div>
  </div>
</div>
