import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewHeaderComponent } from './new-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NewHeaderCartComponent } from './new-header-cart/new-header-cart.component';
import { RouterModule } from '@angular/router';
import { NewHeaderLanguageComponent } from './new-header-language/new-header-language.component';
import { MatMenuModule } from '@angular/material/menu';
import { NewHeaderAccountComponent } from './new-header-account/new-header-account.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { LocationPopupComponent } from './location-popup/location-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { HeaderNotificationComponent } from './header-notification/header-notification.component';

@NgModule({
  declarations: [
    NewHeaderComponent,
    NewHeaderCartComponent,
    NewHeaderLanguageComponent,
    NewHeaderAccountComponent,
    LocationPopupComponent,
    LeftSidebarComponent,
    HeaderNotificationComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    MatMenuModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google_api_key,
      libraries: ['places'],
    }),
  ],
  exports: [NewHeaderComponent],
  entryComponents: [LocationPopupComponent],
})
export class NewHeaderModule {}
