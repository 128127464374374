<app-overlay (categorytogle)="categorytogle()" *ngIf="overlay"></app-overlay>

<section class="web_homePage" [className]="!display ? 'd-none' : ''">
  <!-- <app-header (categorytogle)="categorytogle()"></app-header> -->
  <app-new-header>
    <app-header-search-input class="search"></app-header-search-input>
  </app-new-header>

  <app-sub-menu-category *ngIf="showSubMenu"></app-sub-menu-category>

  <div class="container-fluid menu-bg">
    <app-header-categories></app-header-categories>
  </div>
  <div class="page-content" style="min-height: 750px">
    <!-- content -->
    <router-outlet></router-outlet>
  </div>

  <!--Footer-->
  <footer>
    <app-footer></app-footer>
  </footer>

  <!--Toggle Left Category-->
  <app-toggle-categories
    [display_data]="is_display"
    (categorytogle)="categorytogle()"
    (subMenu)="openSubMenu($event)"
  >
  </app-toggle-categories>

  <app-tap-to-top></app-tap-to-top>
</section>
