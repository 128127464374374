import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-menu-category',
  templateUrl: './sub-menu-category.component.html',
  styleUrls: ['./sub-menu-category.component.scss'],
})
export class SubMenuCategoryComponent implements OnInit {
  subCategory;
  constructor() {}

  ngOnInit(): void {}
}
