import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSearchInputComponent } from './header-search-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [HeaderSearchInputComponent],
  imports: [CommonModule, TranslateModule, FormsModule],
  exports: [HeaderSearchInputComponent],
})
export class HeaderSearchInputModule {}
