import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  showSubMenu: boolean = false;
  display = true;
  overlay = false;
  unsubscribe$ = new SubSink();
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformBrowser(this.platformId)) {
      this.display = false;
    }
  }
  is_display: boolean = false;

  ngOnInit() {}

  categorytogle() {
    this.is_display = this.is_display ? false : true;
    this.openSubMenu(false);
    this.overlay = !this.overlay;
  }

  openSubMenu(event) {
    this.showSubMenu = event;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
