<div class="header__search">
  <select
    [(ngModel)]="selectedCategory"
    (change)="adjustSelectWidth($event.target); slug.focus()"
    [style.width]="selectBoxWidth"
  >
    <option [value]="'all'" class="all-categories-option wrap">
      {{ "All Categories" | translate | titlecase }}
    </option>

    <option
      *ngFor="let category of categoryList"
      [value]="category['category_id']"
    >
      {{ category["category_name"] | titlecase }}
    </option>
  </select>

  <form action="." style="width: 100%">
    <input
      type="text"
      class="header__searchInput cameraFiled"
      placeholder="{{
        'Search by Products, Category, Store, Use & Application' | translate
      }} "
      (keyup.enter)="search(slug.value)"
      (input)="preloadModule()"
      [autofocus]="true"
      #slug
      [style.padding-left]="padding"
    />
  </form>

  <button (click)="search(slug.value)">
    <span class="g_b">
      <img
        alt="null"
        class="search__icon"
        aria-hidden="true"
        width="18"
        height="18"
        src="assets/images/loupe.svg"
      />
    </span>
  </button>
</div>
