import { identifierName } from '@angular/compiler';
import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateShoppingListComponent } from 'src/app/components/pages/shopping-list/create-shopping-list/create-shopping-list.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RequestService } from 'src/app/services/http/request.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import {
  CREATE_SHOPPING_PRODUCT,
  DELETE_SHOPPING_LIST,
  REMOVE_PRODUCT,
  SHOPPING_LIST,
} from 'src/environments/api-path';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-shopping-list-pop-up',
  templateUrl: './shopping-list-pop-up.component.html',
  styleUrls: ['./shopping-list-pop-up.component.scss'],
})
export class ShoppingListPopUpComponent implements OnInit {
  createProduct = {
    keyword: null,
    product_id: null,
    shopping_list_id: null,
  };
  is_login: boolean;
  unsubscribe$ = new SubSink();
  shoppingList: any;
  isLoading: boolean;
  addedShoppingListIdsArray: string[] = [];
  product_id: any;
  reverseShoppingList: any;
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShoppingListPopUpComponent>,
    public dialog: MatDialog,
    private request: RequestService,
    private snack: SnackbarService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.is_login = this.auth.isAuthenticated();

    this.getShoppingList();
    this.product_id = this.data.product_id;

    if (this.data) {
      this.addedShoppingListIdsArray = this.data.added_shopping_list_ids
        ? this.data.added_shopping_list_ids
            .split(',')
            .map((id) => parseInt(id, 10))
        : [];
    }
    // this.data.added_shopping_list_id
  }
  getShoppingList() {
    if (!this.is_login) {
      this.dialogRef.close();
      this.snack.notify('Please sign in to access Shopping Lists', 2);
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 2000);
    }
    this.isLoading = true;
    this.unsubscribe$.add(
      this.request.GET(SHOPPING_LIST, {}).subscribe(
        (response) => {
          this.shoppingList = response['records'];
          this.reverseShoppingList = this.shoppingList.slice().reverse();

          this.isLoading = false;
        },
        (err) => {}
      )
    );
  }
  openDialog() {
    const dialogRef: MatDialogRef<CreateShoppingListComponent> =
      this.dialog.open(CreateShoppingListComponent, {
        data: { type: 'create' },
        panelClass: 'custom-modalbox',
        width: '500px',
      });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getShoppingList();
      }
    });
  }

  passId(id) {
    if (this.addedShoppingListIdsArray.includes(id)) {
      // If the product is in the shopping list, remove it
      if (this.data.product_id) {
        console.log('data.prodict');

        this.removeFromShoppingList(this.data.product_id, id);
      } else {
        this.removeFromShoppingList(this.data.id, id);
      }
    } else {
      if (this.data.product_id) {
        console.log('if', this.data.product_id);

        this.addItem(this.data.product_id, id);
      } else {
        console.log(this.data.id, id);

        this.addItem(this.data.id, id);
      }
    }
    const data = {
      id: id,
      close: true,
    };
    // this.dialogRef.close(data);
  }

  onClose() {
    this.dialogRef.close({ close: false });
  }

  removeFromShoppingList(id, shopping_list_id) {
    this.isLoading = true;
    const url = `${REMOVE_PRODUCT}${id}/${shopping_list_id}`;
    this.request.DELETE(url, {}).subscribe(
      (response) => {
        console.log(response);

        this.isLoading = false;
        this.snack.notify('Item removed from shopping list successfully', 1);
        // this.dialogRef.close();

        // Resume autoplay when a product is removed
        // this.isAutoplayPaused = false;
        // // Check if the Swiper instance exists and autoplay should be resumed
        // if (this.swiperInstance && !this.isAutoplayPaused) {
        //   this.swiperInstance.autoplay.start(); // Resume autoplay
        // }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  deleteList(id, name) {
    const url = DELETE_SHOPPING_LIST + id;
    let dialogRef = this.dialog.open(CreateShoppingListComponent, {
      data: { type: 'delete', id: id, name: name },
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getShoppingList();
      }
    });
  }

  private addItem(product_id, shopping_list_id) {
    this.isLoading = true;
    this.createProduct.product_id = product_id;
    this.createProduct.shopping_list_id = shopping_list_id;

    this.unsubscribe$.add(
      this.request
        .POST(CREATE_SHOPPING_PRODUCT, { ...this.createProduct })
        .subscribe(
          (res) => {
            console.log(res);

            this.isLoading = false;

            if (res.status) {
              this.snack.notify(`Saved to ${res['shopping_list_name']}`, 1);
              // this.dialogRef.close();
            } else {
              this.snack.notify(res.message, 2);
            }
            // this._fetchData();
          },
          (err) => console.log(err)
        )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
