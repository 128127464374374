import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TapToTopComponent } from './tap-to-top.component';

@NgModule({
  declarations: [TapToTopComponent],
  imports: [CommonModule],
  exports: [TapToTopComponent],
})
export class TapToTopModule {}
