/* API path */

/**
 * Apply Promo code
 *
 * @Method : POST
 * @Params :  {
 *              code: string,
 *              subtotal: string
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false}
 *
 */
export const APPLY_PROMO_CODE = 'apply_promo_code';

export const ADDRESS_LIST = 'address/list';

// shopping list api
export const SHOPPING_LIST = 'shopping/list';
export const CREATE_SHOPPING_LIST = 'shopping/list/create';
export const DELETE_SHOPPING_LIST = 'shopping/list/delete/';
export const UPDATE_SHOPPING_LIST = 'shopping/list/update/';
export const SHOPPING_LIST_PRODUCTS = 'shopping/list/product/list';
export const CREATE_SHOPPING_PRODUCT = 'shopping/list/product/create';
export const REMOVE_PRODUCT = 'remove_item_from_shopping_list/';
export const ADD_COMMENT = 'shopping/list/set_comment_proirity_needs_has';

// cart api
export const GET_CART_INFO = 'get-cart';

// place order
export const PLACE_ORDER = 'payment/place_order';

export const CATEGORY_LIST = 'get_category';
export const PANAMA_PROVINCE = 'panama_province';

//vendor signup
export const PANAMA_VENDOR_SIGNUP = 'supplier/signup';

export const CHECKOUT = 'checkout';
export const DROPDOWN_CATEGORY = 'dropdown_category';

export const SIDEBAR_CATEGORY = 'sidebar_category';

// user profile
export const USER_PROFILE = 'my_profile';
export const EDIT_USER_PROFILE = 'edit_profile';

// change password
export const CHANGE_PASSWORD = 'change_password';

export const SEE_MORE_PRODUCT = 'see_moreproduct/slug=';

// get request for parent category list
export const PARENT_CATEGORY_LIST = 'category/list';

// get country wise state
export const STATE_LIST = 'state_by_country/list';
export const ADD_PRODUCT_REVIEW = 'product/review/add';

export const SETUP_INTENT = 'payment/setup_intent';

export const ADD_SELF_PICKUP = 'add/self_pickup-address';
export const GET_SELF_PICKUP = 'get/self_pickup-address';

export const PAYMENT_PREVIEW = 'payment_preview';

export const GET_PAYMENT_METHODS_LIST = 'get/payment_method';

export const ORDERS_LIST = 'my-orders';
export const ORDER_DETAILS = 'order/detail/';

export const DOWNLOAD_INVOICE = 'order/invoice/';
export const REVIEW_PERMISSION = 'get/user_review/';
export const REORDER_LIST = 're-order/products';

export const CANCEL_ORDER = 'order/cancel/';

/**
 * send otp on phone number
 *
 * @Method : POST
 * @Params :  {
 *              mobile: number,
 *              calling_code: alphanumberic  ex: +91
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false}
 *
 */
export const SEND_OTP_ON_PHONE = 'send_otp';

/**
 * Verify phone number
 *
 * @Method : POST
 * @Params :  {
 *              id: number,
 *              otp: number
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false}
 *
 */
export const VERIFY_PHONE_NUMBER = 'verify_mobile_otp';

/**
 * GET BANK LIST
 *
 * @Method : POST
 * @Params :  {
 *
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false,country-id:country_id}
 *
 */
export const GET_BANK_LIST = 'bank/list';

/**
 *Vendor sign up india
 *
 * @Method : POST
 * @Params :  {
 *
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false,country-id:country_id}
 *
 */
export const VENDOR_SIGNUP_PANAMA = 'supplier/save_user_details';
export const VENDOR_REGISTER_PANAMA = 'supplier/panama_signup';
export const WEB_VENDOR_REGISTER_PANAMA = 'vendor-signup-panama-web';
export const LEGAL_NAME = 'vendor-legal-name/verify';

export const WEB_VENDOR_DETAILS_PANAMA = 'vendor-signup-details';
export const VERIFICATION_DOCS = 'vendor-document-upload';
export const VERIFICATION_DOCS_LIST = 'get-documents';
export const VERIFICATION_DOCS_DELETE = 'delete-document';

export const WEB_VENDOR_REGISTER_INDIA = 'vendor-signup-india-web';
export const SEND_VERIFY_OTP_VENDORS = 'verify_email';

/**
 *Vendor sign up india
 *
 * @Method : POST
 * @Params :  {
 *
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false,country-id:country_id}
 *
 */
export const VENDOR_SIGNUP_INDIA = 'supplier/india/save_user_details';

/**
 *Vendor sign up india
 *
 * @Method : POST
 * @Params :  {
 *
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false,country-id:country_id}
 *
 */
export const GET_VENDOR_SAVED_DETAILS_INDIA = 'supplier/india/user_details';

/**
 *Get vendot save forms details
 *
 * @Method : GET
 * @Params :  {
 *
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false,country-id:country_id}
 *
 */
export const GET_VENDOR_SAVED_DETAILS_PANAMA = 'supplier/panama/user_details';

/**
 *verify otp
 *
 * @Method : POST
 * @Params :  {
 *
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false,country-id:country_id}
 *
 */
// export const VERIFY_OTP = 'verify_mobile_otp';
export const VERIFY_OTP = 'verify_otp';

/**
 *verify otp
 *
 * @Method : POST
 * @Params :  {
 *    country_id: number
 *            }
 * @header : {Authorization(Bearer token): false, login_id: false,country-id:country_id}
 *
 */
export const GET_BANK_ACCOUNT_TYPE = 'account/type/list';

export const REMOVE_CARD = 'payment_method/remove';
export const DEFAULT_PAYMENT_METHOD = 'make-default-payment-method';
export const SEARCH_MY_ORDERS = 'my-orders';
export const MAKE_DEFAULT_ADDRESS = 'address/create/default';
export const REORDER_SHOOPING_LIST = 'shopping/list/reorder';
/*CMS pages */
export const CMS_GET = 'cms';
