// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  encryption: false,
  app_name: 'THEIA-ECOM',
  // api_url: 'https://api.theia.flexsin.org/api/v1/',
  api_url: '  https://api.pantheia.com/api/v1/',

  // api_url: ' https://052e2175c259.ngrok.io/api/v1/',
  // api_url: 'http://100.100.6.37:8000/api/v1/',
  google_api_key: 'AIzaSyA-LCC-ocqBajZtOZToyppsEBl5iN3OFdQ&libraries',
  request_encode_key: 'z%C*F-JaNdRgUkXp2s5v8y/B?D(G+KbP',
  s3_bucket_name: 'quickfix-stage',
  region: 'ap-southeast-1',
  // stripe_key:
  //   'pk_test_51HxYfZHzUJEAQWU4KnxM6WfXeQrsMNlsZPSn7aBeXXji2PMmSWndDet6h1O44w6VnUw3kE2ZjgxyRKYuDv9MXg4U003fyLVnbH',
  stripe_key:
    'pk_test_51HxYgiK4JPfOwpchD56XeaoDyupTvbmET8T0JrEdHhYWR7VQqcSM3F4b3cnvQOdp2c0GtGwNTXoVxiizlJrzEhcN00v5tBfE83',
  bucket_access_key: 'AKIAWK7HWGRLSFGQ3GNK',
  bucket_secret_key: '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',
  application_id: '42EX019ZCZ',
  search_api_key: '045fbea61e60bbf6155d84f28764f069',
  cookiesOptions: {
    storeUnencoded: true, // false
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
