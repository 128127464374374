import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderCategoriesComponent } from './header/header-categories/header-categories.component';
import { FooterComponent } from './footer/footer.component';
import { ToggleCategoriesComponent } from './toggle-categories/toggle-categories.component';
import { SubMenuCategoryComponent } from './sub-menu-category/sub-menu-category.component';
import { OverlayComponent } from './overlay/overlay.component';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { TapToTopModule } from 'src/app/shared-component/tap-to-top/tap-to-top.module';
import { NewHeaderModule } from './new-header/new-header.module';
import { HeaderSearchInputModule } from 'src/app/shared-component/header-search-input/header-search-input.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { AboutUsComponent } from './about-us/about-us.component';
@NgModule({
  declarations: [
    LayoutComponent,
    HeaderCategoriesComponent,
    FooterComponent,
    ToggleCategoriesComponent,
    SubMenuCategoryComponent,
    OverlayComponent,
    AboutUsComponent,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    MatExpansionModule,
    RouterModule,
    TapToTopModule,
    NewHeaderModule,
    HeaderSearchInputModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.google_api_key,
    //   libraries: ['places'],
    // }),
  ],
  exports: [RouterModule],
})
export class LayoutModule {}
