import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-header-language',
  templateUrl: './new-header-language.component.html',
  styleUrls: ['./new-header-language.component.scss'],
})
export class NewHeaderLanguageComponent implements OnInit {
  defaultlang: string = 'en';
  languageName;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private auth: AuthService
  ) {
    translate.addLangs(['en', 'es']);
  }
  ngOnInit(): void {
    this.translate.setDefaultLang(this.auth.getlanguage());
    this.defaultlang = this.auth.getlanguage();
    this.getCurrentLanguage();
  }
  switchLang(lang: string, langName) {
    console.log(lang, langName);

    this.defaultlang = lang;
    this.translate.use(lang);
    this.languageName = langName;
    this.auth.setlanguage(lang);
    location.reload();
    // this.router.navigate([''], { skipLocationChange: true }).then(() => {
    //   this.router.navigate([this.router.url]);
    //   console.log('hajskbcsjkdb');
    // });
  }

  getCurrentLanguage() {
    if (this.defaultlang == 'en') {
      this.languageName = 'Eng';
    }
    if (this.defaultlang == 'es') {
      this.languageName = 'Español';
    }
  }
}
