import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { RequestService } from '../../../services/http/request.service';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { SIDEBAR_CATEGORY } from 'src/environments/api-path';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CartService } from 'src/app/services/cart/cart.service';
@Component({
  selector: 'app-toggle-categories',
  templateUrl: './toggle-categories.component.html',
  styleUrls: ['./toggle-categories.component.scss'],
})
export class ToggleCategoriesComponent implements OnInit {
  @Input() display_data: boolean = false;
  @Output() categorytogle = new EventEmitter<number>();
  @Output() subMenu = new EventEmitter();

  display: boolean = false;
  unsubscribe$ = new SubSink();
  loading: boolean = false;
  id;
  sidebar_category: Array<any> = [];
  is_login: boolean;

  constructor(
    private request: RequestService,
    private router: Router,
    private auth: AuthService,
    private cartdata: CartService
  ) {}

  ngOnInit(): void {
    this.getSidebarCategory();
    this.is_login = this.auth.isAuthenticated();
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.display = this.display_data;
  }

  displaycat() {
    this.categorytogle.emit();
  }

  getSidebarCategory() {
    this.unsubscribe$.add(
      this.request.POST(SIDEBAR_CATEGORY, {}).subscribe(
        (response) => {
          if (response['status']) {
            this.sidebar_category = response['records'].filter(
              (x) => x.check === true
            );
          }
        },
        (err) => {
          this.loading = false;
        }
      )
    );
  }

  openSubMenu(id) {
    this.subMenu.emit(true);
    this.id = id;
  }

  closeSubMenu() {
    this.subMenu.emit(false);
  }
  removeCss() {
    this.id = '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  logout() {
    this.loading = true;
    this.request.GET('logout', {}).subscribe(
      (response) => {
        this.loading = false;
        if (response['status']) {
          var unique_id = this.auth.gettokenforcart();
          this.cartdata.getCartcount(unique_id, false);
          this.auth.logOut();
          this.router.navigate(['login']);
        }
      },
      (err) => {
        //console.log(err);
        this.loading = false;
      }
    );
  }
}
