import { Injectable } from '@angular/core';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';
import { Router } from '@angular/router';
// import { CodeReusableService } from '@services/sharedServices/code-reusable.service';

@Injectable({
  providedIn: 'root',
})
export class StorageCookieService {
  [x: string]: any;
  private prefix = environment.app_name;
  private cookieOptions = {
    expires: this.AddDays(7),
    storeUnencoded: true,
  } as CookieOptions;

  constructor(
    private cookie: CookieService,
    private crypto: CryptoService,
    private router: Router // private code: CodeReusableService
  ) {}

  public setCookie(value, key) {
    key = `${this.prefix}-${key}`;
    this.cookie.put(key, value, this.cookieOptions);
  }

  public getCookie(key) {
    key = `${this.prefix}-${key}`;
    return this.cookie.get(key);
  }

  public setCookies(obj: Object, key) {
    key = `${this.prefix}-${key}`;
    this.cookie.putObject(key, obj, this.cookieOptions);
  }

  public getCookies(key) {
    key = `${this.prefix}-${key}`;
    return this.cookie.getObject(key);
  }

  public removeCookie(keys: Array<String>) {
    for (let key of keys) {
      const fullKey = `${this.prefix}-${key}`;
      this.cookie.remove(fullKey);
    }
  }

  public removeAll() {
    this.cookie.removeAll();
    this.router.navigate(['/']);
  }

  AddDays(days) {
    var today = new Date();
    var resultDate = new Date(today);
    resultDate.setDate(today.getDate() + days);
    return resultDate;
  }
}
