<div
  aria-labelledby="addressmodalLabel"
  class="modal right fade logopopup newadddresspopup in show"
  id="addressmodal"
  role="dialog"
  aria-hidden="false"
  style="display: block"
>
  <a
    (click)="closeDialog()"
    _ngcontent-serverapp-c115=""
    href="javascript:void(0)"
    class="sidebar-overlay"
  >
  </a>
  <div class="modal-dialog w3-animate-right" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row w-100">
          <div class="col-12 text-left">
            <h4 class="modal-title">{{ "Map Guide" | translate }}</h4>
          </div>
        </div>
        <svg
          (click)="closeDialog()"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            fill="#000"
            fill-rule="evenodd"
            d="M9.031 8l6.756-6.756a.731.731 0 0 0 0-1.031.732.732 0 0 0-1.031 0L8 6.969 1.244.213a.732.732 0 0 0-1.031 0 .731.731 0 0 0 0 1.03L6.969 8 .213 14.756a.731.731 0 0 0 0 1.031.732.732 0 0 0 1.031 0L8 9.031l6.756 6.756a.732.732 0 0 0 1.031 0 .731.731 0 0 0 0-1.03L9.031 8z"
          ></path>
        </svg>
      </div>
      <div class="modal-body">
        <!---->
        <!-- location popup -->
        <div class="search-address ng-star-inserted">
          <div style="margin: 15px 0px; display: flex">
            <div>
              <svg class="icon icon-location">
                <use xlink:href="#icon-location"></use>
              </svg>
            </div>

            <div class="please-provide-your">
              {{
                "Please provide your  location to see products at nearby store"
                  | translate
              }}
            </div>
          </div>
          <button (click)="setCurrentLocation()" class="detect" type="button">
            {{ "Use my location" | translate }}
          </button>
          <!-- <div class="or"><span></span></div>

          <mat-slider
            class="slider"
            thumbLabel
            [displayWith]="formatLabel"
            min="5"
            [step]="10"
            max="100"
            [(ngModel)]="location.range"
          ></mat-slider> -->
          <div class="or"><span>or</span></div>

          <div class="select-location">Select location via map</div>
          <div class="map">
            <agm-map
              [latitude]="location.lat"
              [longitude]="location.lng"
              [zoom]="zoom"
              (mapReady)="mapReady($event)"
              (mapClick)="markerDragEnd($event)"
              [streetViewControl]="false"
            >
              <input
                #search
                (keydown.enter)="$event.preventDefault()"
                autocapitalize="off"
                autocorrect="off"
                class="form-control search-bx pac-target-input"
                placeholder="Type your city or pincode"
                spellcheck="off"
                type="text"
                autocomplete="off"
                [(ngModel)]="address"
              />
              <!-- <div
                (click)="setCurrentLocation()"
                class="mapcurlocdiv"
                #locationIcon
                style="
                  z-index: 0;
                  position: absolute;
                  bottom: 114px;
                  right: 0px;
                "
              >
                <button
                  class="mapcurlocbtn"
                  title="Your Location"
                  type="button"
                >
                  <div
                    class="mapcurlocimg"
                    style="background-position: 0px 0px"
                  ></div>
                </button>
              </div> -->
              <agm-marker
                [latitude]="location.lat"
                [longitude]="location.lng"
                [markerDraggable]="true"
                (dragEnd)="markerDragEnd($event)"
              >
              </agm-marker>
            </agm-map>
          </div>
        </div>

        <div class="action">
          <button class="detect" type="button" (click)="saveLocation()">
            {{ "Save Location" | translate }}
          </button>
        </div>

        <!---->
      </div>

      <div class="modal-footer d-none"></div>
    </div>
  </div>
</div>

<svg style="display: none">
  <symbol id="icon-location" viewBox="0 0 32 32">
    <path
      d="M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z"
    ></path>
  </symbol>
</svg>
