import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubSink } from 'subsink';
import { RequestService } from 'src/app/services/http/request.service';
import {
  CREATE_SHOPPING_LIST,
  DELETE_SHOPPING_LIST,
  UPDATE_SHOPPING_LIST,
} from 'src/environments/api-path';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-shopping-list',
  templateUrl: './create-shopping-list.component.html',
  styleUrls: ['./create-shopping-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateShoppingListComponent implements OnInit {
  type;
  loading;
  listName;
  unsubscribe$ = new SubSink();
  is_primary = 0;
  checkPrimary = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private request: RequestService,
    private dialogRef: MatDialogRef<CreateShoppingListComponent>,
    private router: Router,
    private snack: SnackbarService
  ) {}

  ngOnInit(): void {
    this.type = this.data['type'];
    this.listName = this.data['name'];
    this.checkPrimary = this.data['is_primary'] || 0;
  }

  setPrimary(event) {
    if (event) {
      this.is_primary = 1;
    } else {
      this.is_primary = 0;
    }
  }

  createList(name) {
    this.loading = true;
    this.unsubscribe$.add(
      this.request
        .POST(CREATE_SHOPPING_LIST, { name: name, is_primary: this.is_primary })
        .subscribe(
          (response) => {
            this.loading = false;
            // this.router.navigate([
            //   `shopping-list/${response['shopping_list_id']}`,
            // ]);
            this.dialogRef.close(true);
          },
          (err) => {
            this.loading = false;
            this.dialogRef.close(false);
          }
        )
    );
  }

  updateList(name) {
    const id = this.data['id'];
    const url = UPDATE_SHOPPING_LIST + id;
    this.loading = true;
    this.request
      .POST(url, { name: name, is_primary: this.is_primary })
      .subscribe(
        (response) => {
          this.loading = false;
          this.dialogRef.close(true);
        },
        (err) => {
          this.loading = false;
          this.dialogRef.close(false);
        }
      );
  }

  deleteList() {
    const id = this.data['id'];
    this.loading = true;
    const url = DELETE_SHOPPING_LIST + id;
    this.request.DELETE(url, {}).subscribe(
      (response) => {
        console.log(response);
        this.loading = false;
        this.snack.notify(
          `List ${
            this.listName.charAt(0).toUpperCase() + this.listName.slice(1)
          } successfully deleted`,
          1
        );

        this.dialogRef.close(true);
      },
      (err) => {
        this.loading = false;
        this.dialogRef.close(false);
      }
    );
  }

  onClose() {
    this.dialogRef.close(false);
  }
}
