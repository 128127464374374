import { Component, OnInit } from '@angular/core';
import { DROPDOWN_CATEGORY } from 'src/environments/api-path';
import { SubSink } from 'subsink';
import { OnDemandPreloadService } from 'src/app/services/pre-loading-strategies/on-demand-preload.service';
import { RequestService } from 'src/app/services/http/request.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-search-input',
  templateUrl: './header-search-input.component.html',
  styleUrls: ['./header-search-input.component.scss'],
})
export class HeaderSearchInputComponent implements OnInit {
  constructor(
    private request: RequestService,
    private preloadService: OnDemandPreloadService,
    private router: Router
  ) {}
  is_login: boolean = false;

  unsubscribe$ = new SubSink();
  categoryList: Array<any> = [];
  selectedCategory: string = 'all';
  padding = '90px';
  selectBoxWidth = '80px';
  ngOnInit() {
    this.gerDropdownCategory();
  }

  search(slug: string) {
    // location.href = `/search?query=${slug}`;
    this.router.navigate(['/search'], {
      queryParams: {
        query: slug,
      },
    });
  }

  preloadModule() {
    this.preloadService.startPreload('search');
  }

  // for setting dynamic width of select box acc to options length
  adjustSelectWidth(e: HTMLSelectElement) {
    // assuming something is always selected
    const displayedText = e.options[e.selectedIndex].innerText;
    const dummy = document.createElement('div');
    dummy.innerText = displayedText;
    dummy.style.position = 'absolute';
    dummy.style.visibility = 'hidden';
    document.body.insertBefore(dummy, document.body.firstChild);
    const measuredWidth = dummy.clientWidth;
    document.body.removeChild(dummy);
    e.style.width = measuredWidth + 45 + 'px';
    this.selectBoxWidth = measuredWidth + 45 + 'px';
    this.padding = measuredWidth + 55 + 'px';
  }

  gerDropdownCategory() {
    this.unsubscribe$.add(
      this.request.POST(DROPDOWN_CATEGORY, {}).subscribe(
        (response) => {
          if (response['status']) {
            this.categoryList = response['records'];
            this.categoryList = this.categoryList.filter(
              (item) => item.check === false
            );
          } else {
            console.log('inside else block');
          }
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
