import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import {
  OnDemandPreloadService,
  OnDemandPreloadOptions,
} from './on-demand-preload.service';
import { Observable, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export declare var navigator;

@Injectable({ providedIn: 'root', deps: [OnDemandPreloadService] })
export class OnDemandPreloadStrategy implements PreloadingStrategy {
  private preloadOnDemand$: Observable<OnDemandPreloadOptions>;

  constructor(private preloadOnDemandService: OnDemandPreloadService) {
    this.preloadOnDemand$ = this.preloadOnDemandService.state$;
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.preloadOnDemand$.pipe(
      /**
       * Using mergeMap because order is not important,
       * and we do not want to cancel previous one.
       * switchMap could cancel previous call.
       * concatMap would make the multiple calls wait for each other.
       */
      mergeMap((preloadOptions) => {
        const shouldPreload = this.preloadCheck(route, preloadOptions);
        // console.log(`${shouldPreload ? '' : 'Not '}Preloading ${route.path}`);
        if (shouldPreload) {
          // console.log(`[🚤Preloading] - On Demand strategy 👉 ${route.path}`);
        }
        return shouldPreload && this.hasGoodConnection(route) ? load() : EMPTY;
      })
    );
  }

  hasGoodConnection(route: Route): boolean {
    const conn = navigator.connection;
    if (conn) {
      if (conn.saveData) {
        return false;
      }
      const avoidTheseConnections = [
        'slow-2g',
        '2g',
        'slow-3g' /* ,'3g',  '4g' */,
      ];
      const effectiveType = conn.effectiveType || '';
      if (avoidTheseConnections.includes(effectiveType)) {
        return false;
      }
    }
    return true;
  }

  private preloadCheck(route: Route, preloadOptions: OnDemandPreloadOptions) {
    return (
      // route?.data?.preload &&
      [route.path, '*'].includes(preloadOptions.routePath) &&
      preloadOptions.preload
    );
  }
}
