<div class="sh-modal" style="position: relative" *ngIf="type == 'create'">
  <svg
    (click)="onClose()"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    class="icon-x"
  >
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M9.031 8l6.756-6.756a.731.731 0 0 0 0-1.031.732.732 0 0 0-1.031 0L8 6.969 1.244.213a.732.732 0 0 0-1.031 0 .731.731 0 0 0 0 1.03L6.969 8 .213 14.756a.731.731 0 0 0 0 1.031.732.732 0 0 0 1.031 0L8 9.031l6.756 6.756a.732.732 0 0 0 1.031 0 .731.731 0 0 0 0-1.03L9.031 8z"
    ></path>
  </svg>
  <div class="sh-md-body">
    <div class="create">
      <h1 class="h1">
        {{ "Create a new list" | translate }}
      </h1>
    </div>

    <div class="p4">
      <label>{{ "Enter List Name" | translate }}</label>
      <input
        type=""
        class="form-control"
        placeholder="{{ 'Enter Name' | translate }}"
        name=""
        #Name
      />
      <label class="vertical-filters-label common-customCheckbox label-check">
        <input
          type="checkbox"
          [checked]="is_primary"
          (click)="setPrimary($event.target.checked)"
        />
        {{ "Set as Primary list" | translate }}
        <div class="common-checkboxIndicator"></div>
      </label>
      <div class="d-flex flex-row-reverse mt-3">
        <button class="btn btn-primary round" (click)="createList(Name.value)">
          {{ "Save" | translate
          }}<i
            *ngIf="loading"
            class="fa fa-spinner fa-spin"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="sh-modal" style="position: relative" *ngIf="type == 'update'">
  <svg
    (click)="onClose()"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    class="icon-x"
  >
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M9.031 8l6.756-6.756a.731.731 0 0 0 0-1.031.732.732 0 0 0-1.031 0L8 6.969 1.244.213a.732.732 0 0 0-1.031 0 .731.731 0 0 0 0 1.03L6.969 8 .213 14.756a.731.731 0 0 0 0 1.031.732.732 0 0 0 1.031 0L8 9.031l6.756 6.756a.732.732 0 0 0 1.031 0 .731.731 0 0 0 0-1.03L9.031 8z"
    ></path>
  </svg>
  <div class="sh-md-body">
    <div class="create">
      <h1 class="h1">
        {{ "Update list" | translate }}
      </h1>
    </div>

    <div class="p4">
      <label>{{ "Enter List Name" | translate }}</label>
      <input
        type=""
        class="form-control"
        placeholder="Name"
        [value]="listName"
        name=""
        #updatedName
      />
      <label
        class="vertical-filters-label common-customCheckbox label-check"
        *ngIf="!checkPrimary"
      >
        <input
          type="checkbox"
          [checked]="is_primary"
          (click)="setPrimary($event.target.checked)"
        />

        {{ "Set as Primary list" | translate }}

        <div class="common-checkboxIndicator"></div>
      </label>
      <div class="d-flex flex-row-reverse mt-3">
        <button
          class="btn btn-primary round"
          (click)="updateList(updatedName.value)"
        >
          {{ "Update" | translate
          }}<i
            *ngIf="loading"
            class="fa fa-spinner fa-spin"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type == 'delete'" style="padding: 24px">
  <div class="container">
    <p style="font-size: 23px">
      {{ "Are you sure you want to delete" | translate }}
      <strong>"{{ listName | titlecase }}"?</strong>
    </p>

    <div>
      <button
        mat-raised-button
        class="d-inline btn-primary"
        id="yes"
        (click)="deleteList()"
      >
        {{ "Yes" | translate }}
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
      <button
        mat-raised-button
        class="d-inline ml-2 no-button"
        (click)="onClose()"
      >
        {{ "No" | translate }}
      </button>
    </div>
  </div>
</div>
