import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpenSidebarService {
  public sidebarSource = new Subject();
  public sidebar$ = this.sidebarSource.asObservable();

  constructor() {}

  openSidebar() {
    this.sidebarSource.next(true);
  }
  closeSidebar() {
    this.sidebarSource.next(false);
  }
}
