import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OnDemandPreloadService } from 'src/app/services/pre-loading-strategies/on-demand-preload.service';
import { CartService } from 'src/app/services/cart/cart.service';

@Component({
  selector: 'app-new-header-cart',
  templateUrl: './new-header-cart.component.html',
  styleUrls: ['./new-header-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewHeaderCartComponent implements OnInit {
  constructor(
    private cartdata: CartService,
    private preloadservice: OnDemandPreloadService
  ) {}
  totalpro: number;
  ngOnInit(): void {
    this.cartdata.totalProduct.subscribe(
      (totalpro) => (this.totalpro = totalpro)
    );
  }

  preloadModule() {
    this.preloadservice.startPreload('viewcart');
  }
}
