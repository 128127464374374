<a
  class="l_a g_a ae_a g_c"
  aria-label="Cart contains 0 items"
  data-uid="KHMcjKE4"
  routerLink="/viewcart"
  (mouseover)="preloadModule()"
  id="hf-cart"
>
  <span class="g_b">
    <span class="b_a b_r">
      <span class="b_a hf-bubble-button-icon-slide b_g b_b b_p">
        <img
          alt="null"
          aria-hidden="true"
          width="24"
          height="24"
          src="assets/images/cart.png"
        />
      </span>
      <span class="b_a ae_b b_g b_b b_p z_a z_h z_i"> </span>
    </span>
  </span>
</a>
<div
  aria-hidden="true"
  id="header-cart-tooltip"
  class="b_a ab_a bl_c z_a z_j z_b b_p"
>
  {{ totalpro }}
</div>
