<div class="left-category" [ngClass]="{ isdisplay: display }">
  <div class="d-flex align-items-center justify-content-between _h">
    <div>
      <img src="assets/images/cat-logo.png" alt="Logo" />
    </div>
    <svg
      (click)="displaycat()"
      width="40"
      height="40"
      viewBox="0 0 24 24"
      class="close-icon"
      fill="#ffffff"
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z" opacity="0.05"></path>
        <path
          fill="#ffffff"
          d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
        ></path>
      </g>
    </svg>
  </div>
  <div class="overAuto">
    <div class="accSectn" (mouseenter)="removeCss(); closeSubMenu()">
      <h3>Pantheia.com</h3>
      <ul>
        <li>
          <i class="_accIcon _accIcon_1 transition"></i>
          <a
            (click)="displaycat()"
            routerLink="/myaccount/user-profile"
            class="transition"
            >{{ "My Account" | translate }}</a
          >
        </li>
        <li>
          <i class="_accIcon _accIcon_2 transition"></i>
          <a
            href="javascript:void(0);"
            class="transition"
            (click)="displaycat()"
            routerLink="/settings/notification"
            >{{ "Notifications" | translate }}</a
          >
        </li>
        <li>
          <i class="_accIcon _accIcon_3 transition"></i>
          <a
            (click)="displaycat()"
            routerLink="/order/orders-history"
            class="transition"
            >{{ "My Orders" | translate }}</a
          >
        </li>
        <li>
          <i class="_accIcon _accIcon_4 transition"></i>
          <a
            (click)="displaycat()"
            routerLink="/order/reorder"
            class="transition"
            >{{ "Reorder Items" | translate }}</a
          >
        </li>
        <li>
          <i class="_accIcon _accIcon_5 transition"></i>
          <a
            href="javascript:void(0)"
            (click)="displaycat()"
            routerLink="/shopping-list"
            class="transition"
            >{{ "Shopping Lists" | translate }}</a
          >
        </li>
      </ul>
    </div>
    <div class="accSectn3">
      <h3>{{ "Categories" | translate }}</h3>
      <mat-accordion *ngIf="sidebar_category">
        <mat-expansion-panel
          *ngFor="let category of sidebar_category"
          (closed)="closeSubMenu()"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ category["category_name"] | titlecase }}</mat-panel-title
            >
          </mat-expansion-panel-header>
          <ul>
            <li
              *ngFor="let subCategory of category.sub_category"
              (mouseenter)="openSubMenu(subCategory['category_id'])"
            >
              <a
                href="javascript:void(0);"
                class="transition ml-5"
                [ngClass]="{ test: id === subCategory['category_id'] }"
                >{{ subCategory["category_name"] | titlecase }}
              </a>
              <span class="float-right"
                ><i class="fa fa-angle-right fa-lg" aria-hidden="true"></i
              ></span>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <ul>
        <li
          *ngFor="let category of sidebar_category"
          (mouseenter)="
            openSubMenu(category['category_id']);
            sendSubCategory(category['sub_category'])
          "
        >
          <div [ngClass]="{ test: id === category['category_id'] }">
            {{ category["category_name"] | titlecase }}
            <span class="float-right"
              ><i class="fa fa-angle-right fa-lg" aria-hidden="true"></i
            ></span>
          </div>
        </li>
      </ul> -->
    </div>
    <div class="accSectn" (mouseenter)="removeCss(); closeSubMenu()">
      <h3>{{ "Help & Settings" | translate }}</h3>
      <ul>
        <li>
          <i class="_accIcon _accIcon_7 transition"></i>
          <a
            routerLink="/settings/notification"
            (click)="displaycat()"
            class="transition"
            >{{ "Settings" | translate }}</a
          >
        </li>
        <li>
          <i class="_accIcon _accIcon_8 transition"></i>
          <a href="javascript" class="transition">{{
            "Customer Service" | translate
          }}</a>
        </li>
        <li>
          <i class="_accIcon _accIcon_9 transition"></i>
          <a href="javascript" class="transition">{{ "Help" | translate }}</a>
        </li>
        <li>
          <i class="_accIcon _accIcon_10 transition"></i>
          <a
            routerLink="/settings/language"
            (click)="displaycat()"
            class="transition"
            >{{ "Language" | translate }}</a
          >
        </li>
        <li *ngIf="!is_login">
          <i class="_accIcon _accIcon_11 transition"></i>
          <a routerLink="/login" class="transition">Sign In</a>
        </li>
        <li *ngIf="is_login" (click)="logout()">
          <i class="_accIcon _accIcon_12 transition"></i>
          <a href="javascript:void(0)" class="transition"
            >{{ "Sign Out" | translate }}
            <div
              *ngIf="loading"
              class="spinner-border text-primary"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div></a
          >
        </li>
      </ul>
    </div>
  </div>
</div>
