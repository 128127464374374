import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { routeUrls } from './routing.urls';
import { OnDemandPreloadStrategy } from '../services/pre-loading-strategies/on-demand-preload-strategy';

const routes: Routes = routeUrls;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      preloadingStrategy: OnDemandPreloadStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
