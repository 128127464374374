<header>
  <div class="sidenav" [class.openSide]="leftMenu">
    <a
      (click)="closeLeftSidebar()"
      href="javascript:void(0)"
      class="sidebar-overlay"
      (mouseover)="closeSubMenu()"
    >
    </a>
    <nav id="unset">
      <div
        class="sideNav-profielBox d-flex align-items-center"
        style="background: #232f3e; padding: 15px"
      >
        <div class="profileImgCircle">
          <!-- <img src="assets/images/cat-logo.png" alt="Logo" /> -->
          <img [src]="userData['profile_image']" alt="Profile Image" />
        </div>
        <div class="userProfileName">
          {{ "Hello" | translate }} {{ userData["first_name"] }}
        </div>
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          class="close-icon"
          fill="#ffffff"
          (click)="closeLeftSidebar()"
        >
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" opacity="0.05"></path>
            <path
              fill="#ffffff"
              d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
            ></path>
          </g>
        </svg>
      </div>
      <div class="overAuto">
        <div class="accSectn" (mouseover)="closeSubMenu()">
          <h3>Pantheia.com</h3>
          <ul>
            <li>
              <i class="_accIcon _accIcon_1 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                routerLink="/myaccount/edit-profile"
                class="transition"
                >{{ "My Account" | translate }}</a
              >
            </li>
            <li>
              <i class="_accIcon _accIcon_2 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                href="javascript:void(0);"
                class="transition"
                routerLink="/settings/notification"
                >{{ "Notifications" | translate }}</a
              >
            </li>
            <li>
              <i class="_accIcon _accIcon_3 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                routerLink="/order/orders-history"
                class="transition"
                >{{ "My Orders" | translate }}</a
              >
            </li>
            <li>
              <i class="_accIcon _accIcon_4 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                routerLink="/order/reorder"
                class="transition"
                >{{ "Reorder Items" | translate }}</a
              >
            </li>
            <li>
              <i class="_accIcon _accIcon_5 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                href="javascript:void(0)"
                routerLink="/shopping-list"
                class="transition"
                >{{ "Shopping Lists" | translate }}</a
              >
            </li>
          </ul>
        </div>
        <div class="accSectn">
          <h3>{{ "Categories" | translate }}</h3>
          <!-- ********************** new sub menu **************************** -->
          <div class="b_a b_f" *ngIf="sidebar_category">
            <button
              (mouseover)="openSubMenu(category)"
              class="g_a r_a ah_c"
              type="button"
              *ngFor="let category of sidebar_category"
            >
              <span class="g_b">
                <span class="b_a b_g b_t">
                  <span class="l_a ah_e l_c">{{
                    category["category_name"] | titlecase
                  }}</span>
                  <img
                    alt="null"
                    aria-hidden="true"
                    width="14"
                    height="14"
                    src="assets/images/right-arrow.svg"
                  />
                </span>
              </span>
            </button>
          </div>

          <!-- ********************** new sub menu end **************************** -->
        </div>
        <div class="accSectn" (mouseover)="closeSubMenu()">
          <h3>{{ "Help & Settings" | translate }}</h3>
          <ul>
            <li>
              <i class="_accIcon _accIcon_7 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                routerLink="/settings/notification"
                class="transition"
                >{{ "Settings" | translate }}</a
              >
            </li>
            <li>
              <i class="_accIcon _accIcon_8 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                href="javascript:void(0)"
                class="transition"
                >{{ "Customer Service" | translate }}</a
              >
            </li>
            <!-- <li>
              <i class="_accIcon _accIcon_9 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                href="javascript:void(0)"
                class="transition"
                >{{ "Help" | translate }}</a
              >
            </li> -->
            <li>
              <i class="_accIcon _accIcon_10 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                routerLink="/settings/language"
                class="transition"
                >{{ "Language" | translate }}</a
              >
            </li>
            <li *ngIf="!is_login">
              <i class="_accIcon _accIcon_11 transition"></i>
              <a
                (click)="closeLeftSidebar()"
                routerLink="/login"
                class="transition"
                >{{ "Sign In" | translate }}</a
              >
            </li>
            <li *ngIf="is_login" (click)="logout()">
              <i class="_accIcon _accIcon_12 transition"></i>
              <a href="javascript:void(0)" class="transition"
                >{{ "Sign Out" | translate }}
                <div
                  *ngIf="loading"
                  class="spinner-border text-primary"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>

<!--   2nd level sub menu -->
<div
  class="aa_a r_a r_aq r_x r_ah r_az r_o r_f bt_e"
  style="
    top: 59px;
    max-height: calc(100vh - 48px);
    min-height: calc(100vh - 48px);
  "
  [hidden]="hide"
>
  <div>
    <div class="r_a bt_a">{{ subCategory.category_name }}</div>

    <div class="b_a b_f">
      <button
        (mouseover)="openThirdLevelSubMenu(category)"
        class="g_a r_a ah_c"
        type="button"
        *ngFor="let category of subCategory.sub_category"
      >
        <span class="g_b">
          <span class="b_a b_g b_t">
            <span class="l_a ah_e l_c">{{
              category["category_name"] | titlecase
            }}</span>

            <img
              alt="null"
              aria-hidden="true"
              width="14"
              height="14"
              src="assets/images/right-arrow.svg"
            />
          </span>
        </span>
      </button>
    </div>
    <!-- <div class="r_a r_e b_a b_t xr">
      <div
        class="r_a r_ag bt_b x_c"
        *ngFor="let item of subCategory.sub_category"
      >
        <div class="b_a b_f">
          <div class="r_a r_ax r_m r_c">
            <span class="z_a z_i z_b">
              <a class="l_a l_c" href="javascript:void(0)">{{
                item.category_name
              }}</a>
            </span>
          </div>
          <a
            class="l_a z_a z_g z_i l_c"
            href="javascript:void(0)"
            *ngFor="let list of item.sub_category"
          >
            <div class="r_a r_l r_b">{{ list.category_name }}</div>
          </a>
        </div>
      </div>
    </div> -->
  </div>
</div>

<!--   3rd level sub menu -->
<div
  class="aa_a r_a r_aq r_x r_ah r_az r_o r_f bt_e tr"
  style="
    top: 59px;
    max-height: calc(100vh - 48px);
    min-height: calc(100vh - 48px);
  "
  [hidden]="hide2"
>
  <div>
    <div class="r_a bt_a">{{ thirdLevelSubCategory.category_name }}</div>

    <div class="b_a b_f">
      <button
        (mouseover)="openFourthLevelSubMenu(category)"
        class="g_a r_a ah_c"
        type="button"
        *ngFor="let category of thirdLevelSubCategory.sub_category"
      >
        <span class="g_b">
          <span class="b_a b_g b_t">
            <span class="l_a ah_e l_c">{{
              category["category_name"] | titlecase
            }}</span>

            <img
              alt="null"
              aria-hidden="true"
              width="14"
              height="14"
              *ngIf="category.sub_category.length"
              src="assets/images/right-arrow.svg"
            />
          </span>
        </span>
      </button>
    </div>
  </div>
</div>

<!--   4rd level sub menu -->
<div
  class="aa_a r_a r_aq r_x r_ah r_az r_o r_f bt_e fr"
  style="
    top: 59px;
    max-height: calc(100vh - 48px);
    min-height: calc(100vh - 48px);
  "
  [hidden]="hide3"
>
  <div>
    <div class="r_a bt_a">{{ fourthLevelSubCategory.category_name }}</div>
    <div class="b_a b_f">
      <button
        class="g_a r_a ah_c"
        type="button"
        *ngFor="let category of fourthLevelSubCategory.sub_category"
      >
        <span class="g_b">
          <span class="b_a b_g b_t">
            <span class="l_a ah_e l_c">{{
              category["category_name"] | titlecase
            }}</span>
          </span>
        </span>
      </button>
    </div>
  </div>
</div>
