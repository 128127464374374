<div class="h-menu" *ngIf="category.length > 0">
  <ul>
    <li
      *ngFor="let data of category; let i = index"
      (mouseover)="preloadModule()"
    >
      <a (click)="gotosearch(data.slug)" href="javascript:void(0)">{{
        data.category_name | titlecase
      }}</a>
    </li>
  </ul>
</div>
