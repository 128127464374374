<footer>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-2">
        <h3>{{ "Get to Know Us" | translate }}</h3>
        <ul class="mb_bg">
          <ng-container *ngFor="let link of footerLink; let last = last">
            <li class="transition" style="margin-bottom: 10px">
              <span (click)="getTitleData($event)">{{ link }}</span>
              <span *ngIf="!last">&nbsp;</span>
            </li>
          </ng-container>
        </ul>

        <!-- <ul class="mb_bg">
          <li>
            <a href="javascript:;" class="trdownansition">{{
              "Careers" | translate
            }}</a>
          </li>
          <li>
            <a (click)="navigateToAboutPantheia()" class="transition">{{
              "About Pantheia" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Press Center" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Services" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Sitemap" | translate
            }}</a>
          </li>
        </ul> -->
      </div>
      <div class="col-12 col-lg-2">
        <h3>Pantheia.com</h3>
        <ul class="mb_bg">
          <li>
            <a routerLink="/terms-use" href="javascript:;" class="transition">{{
              "Terms of Use" | translate
            }}</a>
          </li>
          <li>
            <a
              routerLink="/privacy-policy"
              href="javascript:;"
              class="transition"
              >{{ "Privacy & Security" | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="apple-app-site-association"
              href="javascript:;"
              class="transition"
              >{{ "PrCA Privacy Rights" | translate }}</a
            >
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Our Vendor Partners" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Community" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Announcements" | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-3">
        <h3>{{ "Become a Pantheian Partner!" | translate }}</h3>
        <ul class="mb_bg">
          <li>
            <a href="" routerLink="/vendor-signup" class="transition">{{
              "Become an Affiliated Vendor Partner" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Advertise Your Products" | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-2">
        <h3>{{ "Customer Services" | translate }}</h3>
        <ul class="mb_bg">
          <li>
            <a href="javascript:;" class="transition">{{
              "Help Center" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Your   Account" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Your Reward Points" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Your Orders" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Your Pantheia Card" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Returns or Replacements" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Submit a Dispute" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Contact Us" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Store Pickup" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:;" class="transition">{{
              "Resolution Center" | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-3">
        <h3>{{ "Download App" | translate }}</h3>
        <ul>
          <!-- <li><a href="">Become a seller</a></li> -->
        </ul>
      </div>
    </div>
  </div>
</footer>
<section class="_btmFooter">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <div>
        <ul>
          <li>
            <a href="javascript:;">
              <img src="assets/images/fb-icon.png" alt="icon" />
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <img src="assets/images/twitter-icon.png" alt="icon" />
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <img src="assets/images/insta-icon.png" alt="icon" />
            </a>
          </li>
        </ul>
      </div>
      <div>
        <span
          >©{{
            "2020 Copyright Pantheia. All Rights Reserved" | translate
          }}</span
        >
      </div>
      <div>
        <a href="javascript:;">
          <img src="assets/images/payment-icon.png" alt="icon" />
        </a>
      </div>
    </div>
  </div>
</section>
<!-- <div [innerHTML]="cmsPage"></div> -->
