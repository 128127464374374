import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { SubSink } from 'subsink';
import { RequestService } from 'src/app/services/http/request.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { Router } from '@angular/router';
import { SIDEBAR_CATEGORY, USER_PROFILE } from 'src/environments/api-path';
import { DOCUMENT } from '@angular/common';
import { OpenSidebarService } from 'src/app/services/open-sidebar/open-sidebar.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit {
  leftMenu: boolean = false;
  unsubscribe$ = new SubSink();
  loading: boolean = false;
  id;
  sidebar_category: Array<any> = [];
  is_login: boolean;
  hide = true;
  subCategory = {
    category_name: '',
    sub_category: [],
  };
  hide2 = true;
  thirdLevelSubCategory = {
    category_name: '',
    sub_category: [],
  };
  hide3 = true;
  fourthLevelSubCategory = {
    category_name: '',
    sub_category: [],
  };

  userData: any = {};
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private request: RequestService,
    private router: Router,
    private auth: AuthService,
    private cartdata: CartService,
    private sidebarService: OpenSidebarService
  ) {}

  ngOnInit(): void {
    this.getSidebarCategory();
    this.is_login = this.auth.isAuthenticated();
    this.getUser();
    this.unsubscribe$.add(
      this.sidebarService.sidebar$.subscribe((res) => {
        if (res) {
          this.openLeftSidebar();
        }
      })
    );
  }

  getSidebarCategory() {
    this.unsubscribe$.add(
      this.request.POST(SIDEBAR_CATEGORY, {}).subscribe(
        (response) => {
          if (response['status']) {
            this.sidebar_category = response['records'];
            // .filter(
            //   (x) => x.check === false
            // );
            // console.log(this.sidebar_category);
          }
        },
        (err) => {
          this.loading = false;
        }
      )
    );
  }

  openLeftSidebar() {
    this.leftMenu = true;
    this.renderer.addClass(this.document.body, 'no-scroll');
  }

  closeLeftSidebar() {
    this.leftMenu = false;
    this.hide = true;
    this.renderer.removeClass(this.document.body, 'no-scroll');
  }

  openSubMenu(category) {
    this.hide = false;
    this.hide2 = true;
    this.subCategory = category;
  }

  closeSubMenu() {
    this.hide = true;
    this.hide2 = true;
    this.hide3 = true;
  }

  openThirdLevelSubMenu(category) {
    this.hide2 = false;
    this.hide3 = true;
    this.thirdLevelSubCategory = category;
  }

  openFourthLevelSubMenu(category) {
    if (category.sub_category.length) {
      this.hide3 = false;
      this.fourthLevelSubCategory = category;
    } else {
      this.hide3 = true;
    }
  }

  logout() {
    this.loading = true;
    this.request.GET('logout', {}).subscribe(
      (response) => {
        this.loading = false;
        if (response['status']) {
          var unique_id = this.auth.gettokenforcart();
          this.cartdata.getCartcount(unique_id, false);
          this.auth.logOut();
          this.router.navigate(['login']);
        }
      },
      (err) => {
        //console.log(err);
        this.loading = false;
      }
    );
  }

  getUser() {
    this.loading = true;
    this.unsubscribe$.add(
      this.request.POST(USER_PROFILE, {}).subscribe(
        (response) => {
          if (response['status']) {
            this.userData = response['record'];
            this.loading = false;
          }
        },
        (err) => {
          this.loading = false;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
