<button
  class="g_a ae_a g_c"
  type="button"
  aria-label="Your Account"
  data-uid="5Sns6ll6"
  id="hf-account-flyout"
  (click)="openRightSidebar()"
>
  <!-- [matMenuTriggerFor]="belowMenu" -->
  <span class="g_b"
    ><span class="b_a b_r"
      ><span class="b_a hf-bubble-button-icon-slide b_g b_b b_p">
        <img
          alt="null"
          aria-hidden="true"
          width="24"
          height="24"
          src="assets/images/account.svg"
        /> </span
      ><span class="b_a ae_b b_g b_b b_p z_a z_h z_i">{{
        "Account" | translate
      }}</span></span
    ></span
  >
</button>
<!-- drop down for vendor accounts -->
<mat-menu #belowMenu="matMenu" yPosition="below">
  <button mat-menu-item>Account</button>
  <button mat-menu-item>Vendor</button>
</mat-menu>
<!-- side nav -->

<header>
  <div id="mySidenav" class="sidenav" [class.openSide]="rightMenu">
    <a
      (click)="closeRightSidebar()"
      href="javascript:void(0)"
      class="sidebar-overlay"
    ></a>
    <nav id="unset" class="">
      <div id="sub-menu" class="sidebar-menu">
        <div class="sidenav__base">
          <div class="account__menu">
            <div class="account__title">
              <div class="b_a b_g">
                <div
                  data-tl-id="header-account-menu-icon"
                  class="b_a g_a bs_b g_c b_g b_b b_p"
                >
                  <span class="g_b accountUser-imageBox">
                    <!-- <img
                      alt="null"
                      aria-hidden="true"
                      width="24"
                      height="24"
                      src="assets/images/account.svg"
                    /> -->

                    <i><img src="{{ ImgProfile }}" alt="image" /></i>
                  </span>
                </div>
                <span
                  class="r_a r_v bs_f"
                  data-tl-id="header-account-menu-title"
                >
                  {{ translatedText | titlecase }}
                </span>
              </div>
              <svg
                (click)="closeRightSidebar()"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="black"
                class="close-icon"
              >
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0h24v24H0z" opacity="0.05"></path>
                  <path
                    fill="black"
                    d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
                  ></path>
                </g>
              </svg>
            </div>
            <div class="r_a r_o">
              <div
                data-tl-id="header-account-links-signed-out"
                class="b_a r_a r_az r_o r_f bs_e b_f"
              >
                <a
                  class="l_a"
                  routerLink="/myaccount/edit-profile"
                  title="Account"
                  (click)="closeRightSidebar()"
                >
                  <div aria-hidden="true" class="b_a r_a r_ax r_m r_c b_g">
                    <img
                      alt="null"
                      aria-hidden="true"
                      width="18"
                      height="18"
                      src="assets/images/account-black.svg"
                    />

                    <div class="r_a r_ag"></div>
                    <span class="l_a l_c">
                      <div class="z_a z_i">{{ "My Profile" | translate }}</div>
                    </span>
                  </div>
                </a>
                <a
                  class="l_a"
                  routerLink="/shopping-list"
                  title="Shopping lists"
                  (click)="closeRightSidebar()"
                >
                  <div aria-hidden="true" class="b_a r_a r_ax r_m r_c b_g">
                    <svg
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink:href="http://www.w3.org/1999/xlink"
                    >
                      <path
                        d="M 20 2.667969 C 20.738281 2.667969 21.332031 3.261719 21.332031 4 L 21.332031 21.332031 C 21.332031 22.070312 20.738281 22.667969 20 22.667969 L 4 22.667969 C 3.261719 22.667969 2.667969 22.070312 2.667969 21.332031 L 2.667969 4 C 2.667969 3.261719 3.261719 2.667969 4 2.667969 Z M 20 4 L 4 4 L 4 21.332031 L 20 21.332031 Z M 16.667969 16 C 17.035156 16 17.332031 16.296875 17.332031 16.667969 C 17.332031 17.035156 17.035156 17.332031 16.667969 17.332031 L 10 17.332031 C 9.632812 17.332031 9.332031 17.035156 9.332031 16.667969 C 9.332031 16.296875 9.632812 16 10 16 Z M 7.332031 16 C 7.703125 16 8 16.296875 8 16.667969 C 8 17.035156 7.703125 17.332031 7.332031 17.332031 C 6.964844 17.332031 6.667969 17.035156 6.667969 16.667969 C 6.667969 16.296875 6.964844 16 7.332031 16 Z M 16.667969 12 C 17.035156 12 17.332031 12.296875 17.332031 12.667969 C 17.332031 13.035156 17.035156 13.332031 16.667969 13.332031 L 10 13.332031 C 9.632812 13.332031 9.332031 13.035156 9.332031 12.667969 C 9.332031 12.296875 9.632812 12 10 12 Z M 7.332031 12 C 7.703125 12 8 12.296875 8 12.667969 C 8 13.035156 7.703125 13.332031 7.332031 13.332031 C 6.964844 13.332031 6.667969 13.035156 6.667969 12.667969 C 6.667969 12.296875 6.964844 12 7.332031 12 Z M 16.667969 8 C 17.035156 8 17.332031 8.296875 17.332031 8.667969 C 17.332031 9.035156 17.035156 9.332031 16.667969 9.332031 L 10 9.332031 C 9.632812 9.332031 9.332031 9.035156 9.332031 8.667969 C 9.332031 8.296875 9.632812 8 10 8 Z M 7.332031 8 C 7.703125 8 8 8.296875 8 8.667969 C 8 9.035156 7.703125 9.332031 7.332031 9.332031 C 6.964844 9.332031 6.667969 9.035156 6.667969 8.667969 C 6.667969 8.296875 6.964844 8 7.332031 8 Z M 7.332031 8 "
                      ></path>
                    </svg>
                    <div class="r_a r_ag"></div>
                    <span class="l_a l_c">
                      <div class="z_a z_i">
                        {{ "My Shopping lists" | translate }}
                      </div>
                    </span>
                  </div>
                </a>

                <a
                  class="l_a"
                  routerLink="/order/orders-history"
                  title="Track Orders"
                  (click)="closeRightSidebar()"
                >
                  <div aria-hidden="true" class="b_a r_a r_ax r_m r_c b_g">
                    <img
                      alt="null"
                      aria-hidden="true"
                      width="18"
                      height="18"
                      src="assets/images/icon11.png"
                    />
                    <div class="r_a r_ag"></div>
                    <span class="l_a l_c">
                      <div class="z_a z_i">{{ "My Orders" | translate }}</div>
                    </span>
                  </div>
                </a>
                <a class="l_a" (click)="closeRightSidebar()" title="Help">
                  <div aria-hidden="true" class="b_a r_a r_ax r_m r_c b_g">
                    <img
                      alt="null"
                      aria-hidden="true"
                      width="18"
                      height="18"
                      src="assets/images/help.svg"
                    />
                    <div class="r_a r_ag"></div>
                    <span class="l_a l_c">
                      <div class="z_a z_i">{{ "Help" | translate }}</div>
                    </span>
                  </div>
                </a>
                <a
                  class="l_a"
                  *ngIf="!is_login"
                  routerLink="/login"
                  title="Sign In"
                  (click)="closeRightSidebar()"
                >
                  <div aria-hidden="true" class="b_a r_a r_ax r_m r_c b_g">
                    <img
                      alt="null"
                      aria-hidden="true"
                      width="18"
                      height="18"
                      src="assets/images/sign-in.svg"
                    />
                    <div class="r_a r_ag"></div>
                    <span class="l_a l_c">
                      <div class="z_a z_i">{{ "Sign In" | translate }}</div>
                    </span>
                  </div>
                </a>
                <a
                  class="l_a"
                  title="Sign out"
                  *ngIf="is_login"
                  (click)="logout()"
                >
                  <div aria-hidden="true" class="b_a r_a r_ax r_m r_c b_g">
                    <img
                      alt="null"
                      aria-hidden="true"
                      width="18"
                      height="18"
                      src="assets/images/sign-out.svg"
                    />
                    <div class="r_a r_ag"></div>
                    <span class="l_a l_c">
                      <div class="z_a z_i">
                        Sign out
                        <div
                          *ngIf="loading"
                          class="spinner-border text-primary"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>
