<div class="sub-cat" *ngIf="subCategory">
  <div>
    <div class="row">
      <div class="col-md-6">
        <ul>
          <li *ngFor="let list of subCategory">
            <i class="_accIcon _accIcon_1 transition"></i>
            <a
              routerLink="/category/{{ list['slug'] }}"
              [queryParams]="{ s: list['slug'] }"
              class="transition"
              >{{ list["category_name"] | titlecase }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
