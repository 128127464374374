import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { RequestService } from '../http/request.service';
@Injectable({
  providedIn: 'root',
})
export class CartService {
  private productSource = new BehaviorSubject(0);
  totalProduct = this.productSource.asObservable();
  user_id: string = '0';
  total_product: number = 0;
  is_login: boolean = false;
  constructor(private auth: AuthService, private request: RequestService) {
    this.setcart();
  }

  setcart(): void {
    this.is_login = this.auth.isAuthenticated();
    if (this.is_login) {
      this.user_id = this.auth.getUser();
      this.getCartcount(this.user_id, true);
    } else {
      if (localStorage.getItem('unique_id')) {
        this.user_id = localStorage.getItem('unique_id');

        this.getCartcount(this.user_id, false);
      } else {
        this.user_id = this.auth.gettokenforcart();
        this.getCartcount(this.user_id, false);
      }
    }
  }
  getCartcount(user_id, is_login) {
    if (is_login === false) {
      localStorage.setItem('unique_id', user_id);
    }
    if (is_login) {
      var requestdata = { unique_id: null, user_id: user_id };
    } else {
      var requestdata = { unique_id: user_id, user_id: null };
    }
    this.request.POST('get-total-cart-count', requestdata).subscribe(
      (response) => {
        if (response['status']) {
          this.productSource.next(response['count']);
          this.total_product = response['count'];
        } else {
        }
      },
      (err) => {}
    );
  }
  addProduct() {
    this.productSource.next(++this.total_product);
  }
  removeProduct() {
    this.productSource.next(--this.total_product);
  }

  setProductSource(totalCount: number) {
    this.productSource.next(0);
  }
}
